import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`És el major representant del gènere amb uns capells de convexos a quasi plans, de 5 a 15 cm de diàmetre de color ataronjat, amb superfície seca. Les làmines estan poc atapeïdes, dentades i del mateix color que el capell. El peu és gruixat i corbat, cilíndric, atenuat a la base, de 8 a 10 x 1 a 2,5 cm. Presència d’anell, que és persistent, membranós i de color groc, quedant tenyit d’ocre per la caiguda de les espores. Aquestes són ocràcies en massa, en forma d’ametla, berrugoses, de 7-9,5 x 5-6 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      